@font-face {
  font-family: 'big_john_probold';
  src: url('bigjohnpro-bold-webfont.woff2') format('woff2'),
    url('bigjohnpro-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'big_john_prolight';
  src: url('bigjohnpro-light-webfont.woff2') format('woff2'),
    url('bigjohnpro-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  background: linear-gradient(
    90deg,
    #8b0000 calc(50%),
    #f9eddd calc(50%)
  ); // 75deg for angle
  height: 100vh;
  font-size: 1.5rem;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  height: 100vh;
}

.greeting {
  font-size: 3rem;
  font-family: 'big_john_probold';
  text-align: right;
  margin-right: 1rem;
  color: #f9eddd;
}

.copy {
  font-family: 'big_john_prolight';
  width: 90%;
  margin-left: 1rem;
}

h1,
h3 {
  margin: 0;
}

@media (max-width: 600px) {
  .container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
  body {
    margin: 0;
    background: linear-gradient(
      187deg,
      #8b0000 calc(50%),
      #f9eddd calc(50%)
    ); // 75deg for angle
  }
  .greeting {
    text-align: center;
  }
}
